module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3c049c05873520f389800b553079d9ea"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://paypo.ro","noQueryString":true,"exclude":["https://paypo.ro/kampanie/black-month","https://paypo.ro/kampanie/kampania-B2S","https://paypo.ro/kampanie/kampania-black-month","https://paypo.ro/kampanie/kampania-payu","https://paypo.ro/kampanie/odkryj-swiat-po","https://paypo.ro/kampanie/promo","https://paypo.ro/kampanie/testowanie-karty","https://paypo.ro/kampanie/rejestracja","https://paypo.ro/p/archiwum-obowiazkow-informacyjnych","https://paypo.ro/p/dostepnesklepy","https://paypo.ro/p/kontakt","https://paypo.ro/p/podziel-na-3","https://paypo.ro/p/regulaminy","https://paypo.ro/p/zaplac-za-30-dni","https://paypo.ro/pay/error","https://paypo.ro/pay/success"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
