import * as Sentry from '@sentry/gatsby';
import { browserTracingIntegration } from '@sentry/gatsby';
import { captureConsoleIntegration } from '@sentry/integrations';

import {
    sentryIgnoreExceptionTypes,
    sentryIgnoreExceptionValues,
    sentryIgnoreMessages,
} from './src/utils/sentryIgnoreList';

Sentry.init({
    dsn: process.env.GATSBY_APP_SENTRY_DSN,
    environment: process.env.GATSBY_APP_ENVIRONMENT,
    release: process.env.GATSBY_APP_RELEASE,
    integrations: [
        browserTracingIntegration(),
        captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
    debug: true,
    sendDefaultPii: true,
    tracesSampleRate: 0.25,
    sampleRate: 0.25,
    enabled: true,
    beforeSend(event) {
        if (
            event?.exception &&
            event.exception?.values &&
            event.exception.values.length > 0
        ) {
            const { value, type } = event.exception.values[0];

            if (
                sentryIgnoreExceptionValues.some(ignoreValue =>
                    value?.includes(ignoreValue),
                ) ||
                sentryIgnoreExceptionTypes.some(ignoreType =>
                    type?.includes(ignoreType),
                )
            ) {
                return null;
            }
        }

        if (
            event?.message &&
            sentryIgnoreMessages.some(ignoreMessage =>
                event?.message.includes(ignoreMessage),
            )
        ) {
            return null;
        }
    },
});
