exports.components = {
  "component---src-sites-ro-404-js": () => import("./../../../src/sites/RO/404.js" /* webpackChunkName: "component---src-sites-ro-404-js" */),
  "component---src-sites-ro-index-jsx": () => import("./../../../src/sites/RO/index.jsx" /* webpackChunkName: "component---src-sites-ro-index-jsx" */),
  "component---src-sites-ro-p-categorii-prismic-category-uid-jsx": () => import("./../../../src/sites/RO/p/categorii/{PrismicCategory.uid}.jsx" /* webpackChunkName: "component---src-sites-ro-p-categorii-prismic-category-uid-jsx" */),
  "component---src-sites-ro-p-comercianti-jsx": () => import("./../../../src/sites/RO/p/comercianti.jsx" /* webpackChunkName: "component---src-sites-ro-p-comercianti-jsx" */),
  "component---src-sites-ro-p-contact-jsx": () => import("./../../../src/sites/RO/p/contact.jsx" /* webpackChunkName: "component---src-sites-ro-p-contact-jsx" */),
  "component---src-sites-ro-p-magazineledisponibile-jsx": () => import("./../../../src/sites/RO/p/magazineledisponibile.jsx" /* webpackChunkName: "component---src-sites-ro-p-magazineledisponibile-jsx" */),
  "component---src-sites-ro-pay-error-jsx": () => import("./../../../src/sites/RO/pay/error.jsx" /* webpackChunkName: "component---src-sites-ro-pay-error-jsx" */),
  "component---src-sites-ro-pay-success-jsx": () => import("./../../../src/sites/RO/pay/success.jsx" /* webpackChunkName: "component---src-sites-ro-pay-success-jsx" */),
  "component---src-sites-ro-pay-transaction-uid-jsx": () => import("./../../../src/sites/RO/pay/[transactionUid].jsx" /* webpackChunkName: "component---src-sites-ro-pay-transaction-uid-jsx" */),
  "component---src-templates-customer-dashboard-tiles-jsx": () => import("./../../../src/templates/CustomerDashboardTiles.jsx" /* webpackChunkName: "component---src-templates-customer-dashboard-tiles-jsx" */),
  "component---src-templates-dostepnesklepy-jsx": () => import("./../../../src/templates/dostepnesklepy.jsx" /* webpackChunkName: "component---src-templates-dostepnesklepy-jsx" */)
}

