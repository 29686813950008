export const sentryIgnoreMessages = [
    'not found. Not rendering React',
    'Sender: Failed to get initial state. Please report this bug.',
    'Serialiser failed',
    'NotAllowedError: Write permission denied.',
    'XhrError',
];

export const sentryIgnoreExceptionValues = [
    'not found. Not rendering React',
    'We couldn\'t load "https://assets.paypo.pl',
    'Load failed',
    "Cannot read properties of undefined (reading 'logging')",
    'Permission denied to access property "dispatchEvent" on cross-origin object',
    "JSON Parse error: Expected ':' before value in object property definition",
    'Non-Error promise rejection captured with value',
    'ResizeObserver loop completed with undelivered notifications.',
    'fbq is not defined',
    "Cannot read properties of undefined (reading 'query')",
    'Cannot redefine property: ethereum',
    'Cannot redefine property: googletag',
    'Cannot redefine property: BetterJsPop',
    'fullHrefManual is undefined',
    "Identifier 'originalPrompt' has already been declared",
    'No error message',
    'Target container is not a DOM element.',
    '\'this.container.query(".signal--expanded").width\' is undefined',
    'reCAPTCHA client element has been removed: 100000',
    'can\'t redefine non-configurable property "userAgent"',
    'cancelled',
    'chain is not set up',
    'null is not an object (evaluating \'document.querySelector(".overpayment-modal-content").style\')',
    "Cannot read properties of undefined (reading 'domInteractive')",
    "Cannot read properties of undefined (reading 'substring')",
    'timeout exceeded',
    'Request failed with status code 400',
    "Cannot read property 'style' of null",
    'The operation is insecure',
    'Failed to fetch',
    'Illegal invocation',
    'Unexpected token ?',
    'Write permission denied.',
    'undefined is not an object ',
    'Request aborted',
    'Object Not Found Matching Id',
    "Expected ':' after property name in JSON at position",
    'Timeout',
    "Cannot read properties of undefined (reading 'page')",
    "undefined is not an object (evaluating 'n.length')",
    "Cannot read properties of undefined (reading 'domInteractive')",
    'anulowane',
    'XhrError',
    'Cookiebot',
    'Unexpected token \'a\', "active" is not valid',
    'Network Error',
];

export const sentryIgnoreExceptionTypes = [
    'UnhandledRejection',
    'AxiosError',
    'NS_ERROR_FAILURE',
    'NS_ERROR_ABORT',
    'NS_ERROR_ABORT',
];
